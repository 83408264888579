import React from "react";
import Icon, { IconType } from "../Icon";

interface QuoteProps {
  text: string;
  inline?: any;
}

const Quote: React.FC<QuoteProps> = props => {
  const { text, inline } = props;

  return (
    <div className="lg:min-w-[120%]">
      <div className="flex pt-8 items-center">
        <span className={`flex items-center mb-5 text-gray-1 ${inline ? "ml-auto" : ""} space-x-3`}>
          <Icon className="fill-current mr-2" iconType={IconType.twitter} />
          {/* Need to add this functionality */}
          Tweet this quote →
        </span>
      </div>
      <div className={`font-semibold text-4xl text-gray-2 leading-5xl pb-12 ${inline ? "w-2/3" : ""}`}>
        {text}
      </div>
    </div>
  );
};

export default Quote;
