import React from "react";
import TopImageCard from "../TopImageCard";

interface RecommendationsProps {
  title?: string;
  publications?: any[];
}

const Recommendations: React.FC<RecommendationsProps> = (props: any) => {
  const { title, publications } = props;
  return (
    <div className="pb-8 xl:pr-12">
      <p className="pl-12 text-4xl gray-dark-4 font-semibold pb-6">{title}</p>
      <div
        className="flex justify-start lg:justify-between no-scrollbar lg:overflow-x-auto space-x-4 overflow-x-scroll mx-auto"
        style={{
          overflowX: "auto"
        }}
      >
        {publications.map((node, i) => {
          const publication = node?.node;
          return (
            <TopImageCard
              className={`flex-shrink-0 max-w-[300px] ${
                i === 0 ? "ml-12" : ""
              }`}
              key={publication.id}
              publication={publication}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Recommendations;
