import { Link } from "gatsby";
import React from "react";
import * as styles from "./TopImageCard.module.scss";
import Icon, { IconType } from "../../Icon";
import classNames from "classnames";

interface TopImageCardProps {
  className?: string;
  publication?: any;
}
const TopImageCard: React.FC<TopImageCardProps> = props => {
  const { publication, className } = props;

  const image =
    publication.bannerImage &&
    (publication?.bannerImage?.fluid ||
      publication?.bannerImage?.fluid);

  return (
    <Link
      to={`/blog/${publication.slug}/`}
      className={classNames(
        "flex flex-col justify-start pb-8 relative h-[342px]",
        styles.top_image_card,
        className
      )}
    >
      <div className="h-44 overflow-hidden relative rounded-md">
        <img
          src={image?.src}
          className="w-full absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
          alt="preview"
        />
      </div>
      <div className="flex flex-col absolute top-1/2">
        <div className="flex justify-between pt-6 pb-2 ">
          <p className="text-indigo-1 text-sm">
            {publication?.categories?.[0].name}
          </p>
          <p className="text-base font-medium ml-auto flex">
            {`${publication.timeToRead || 0} min`}
            <Icon iconType={IconType.clock} className="ml-3" />
          </p>
        </div>
        <h3 className="text-xl font-semibold mb-4 max-h-20 overflow-hidden">
          {publication.title}
        </h3>
      </div>
      <p className="text-gray-2 text-sm absolute bottom-0">
        {publication.publishDate}
      </p>
    </Link>
  );
};

export default TopImageCard;
