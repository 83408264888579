import { graphql } from "gatsby";
import React from "react";
import RichText from "../../components/RichText";
import BlogSeo from "../../components/Seo/BlogSeo";
import Layout from "../../containers/Layout";
import Icon, { IconType } from "../../components/Icon";
import { formatBreadcrumb } from "../../utils/breadcrumb";
import RelatedArticle from "../../components/Blog/RelatedArticle";
import Section from "../../containers/Section";
import Recommendations from "../../components/Blog/Recommendations";
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface BlogArticleProps {
  data: any;
}

const BlogArticle: React.FC<BlogArticleProps> = props => {
  const { data } = props;

  const {t} = useTranslation();

  const publication = data?.publication;
  const featuredPublications = data?.featuredPublications?.edges;
  const image = publication?.bannerImage?.fluid?.src;

  //Format date since different format needed for schema
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return new Date(dateString).toLocaleDateString([], options);
  };

  return (
    <Layout>
      <BlogSeo
        data={publication}
        title="Perpetua Blog Post"
        description="Perpetua's blog and media hub, keeping you up-to-date with trends, information, and strategies to take your eCommerce advertising to new heights."
      />
      <Section>
        <div className="grid grid-cols-8">
          <div className="col-span-8 pt-[150px]">
            {formatBreadcrumb(props.path)}
          </div>
          <div className="col-span-8 lg:col-span-5">
            <div className="pt-4 pb-3">
              <img src={image} width="100%" className="rounded-lg" />
            </div>
            <div className="pt-[12px]">
              <div className="flex justify-between">
                {publication?.categories && (
                  <p className="text-green-1">
                    {t(publication?.categories[0]?.name)}
                  </p>
                )}
                <span className="flex">
                  {publication?.timeToRead} min
                  <Icon iconType={IconType.clock} className="ml-3" />
                </span>
              </div>
              <p className="gray-dark-4 font-medium text-4xl py-6">
                {publication?.title}
              </p>
              <p className="font-narrow text-gray-8">
                By{" "}
                <span className="font-medium text-gray-1">
                  {publication?.authors?.map(a => a?.name).join(", ")}
                </span>{" "}
                on{" "}
                <span className="font-medium text-gray-1">
                  {formatDate(publication?.publishDate)}
                </span>
              </p>
              <div className="grid grid-cols-6 pt-16 md:pt-[38px] w-full">
                <div className="col-span-6 md:col-span-1 pb-16 lg:pt-0 mx-auto lg:mx-0 lg:pb-0 lg:inline">
                  <div className="flex justify-center items-center space-x-8 md:space-x-0 md:block sticky top-40 md:space-y-6">
                    <a
                      className="block"
                      href="https://www.facebook.com/perpetualabs/"
                    >
                      <Icon iconType={IconType.facebookColored} />
                    </a>
                    <a
                      className="block"
                      href="https://www.linkedin.com/company/perpetua-labs/"
                    >
                      <Icon iconType={IconType.linkedinColored} />
                    </a>
                    <a
                      className="block"
                      href="https://twitter.com/PerpetuaLabs"
                    >
                      <Icon iconType={IconType.twitterColored} />
                    </a>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-5 lg:col-span-5">
                  <div className="w-full">
                    {publication?.body && <RichText data={publication?.body} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:col-span-2 lg:col-start-7">
            <p className="text-4xl gray-dark-4 font-semibold pb-6">
              {t("Related Articles")}
            </p>
            {featuredPublications.map(node => {
              const publication = node?.node;
              return (
                <RelatedArticle
                  key={publication.id}
                  publication={publication}
                />
              );
            })}
          </div>
        </div>
      </Section>
      <div className="mx-0 mt-20 px-0 lg:mx-auto w-full lg:max-w-8xl">
        <Recommendations
          publications={featuredPublications}
          title="Related Stories"
        />
      </div>
    </Layout>
  );
};

export default BlogArticle;

export const query = graphql`
  query PublicationBySlug2($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          language
          data
          ns
        }
      }
    }
    walmart: file(relativePath: { eq: "blogImages/walmart.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    publication: contentfulPublication(slug: { eq: $slug }) {
      id
      title
      slug
      publishDate(formatString: "YYYY-MM-DD")
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      timeToRead
      bannerImage {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bodyPreview
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            fluid {
              src
            }
          }
          ... on ContentfulPerpetuaEmbeddedContent {
            contentful_id
            script {
              script
            }
          }
        }
      }
      authors {
        name
      }
      categories {
        name
      }
    }
    featuredPublications: allContentfulPublication(
      limit: 4
      filter: { slug: { ne: $slug }, title: {ne: null}, node_locale: {eq: $language}}
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          timeToRead
          bannerImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          bodyPreview
          body {
            raw
          }
          authors {
            name
          }
          categories {
            name
          }
        }
      }
    }
  }
`;
