import { Link } from "gatsby";
import React from "react";
import * as styles from "./RelatedArticle.module.scss";
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface RelatedArticleProps {
  publication: any;
}

const RelatedArticle: React.FC<RelatedArticleProps> = props => {
  const { publication } = props;
  const {t} = useTranslation();

  return (
    <Link to={`/blog/${publication.slug}/`} className={styles.top_image_card}>
      <div className={styles.card_info}>
        <p className={styles.title}>{publication.title}</p>
        <div className={styles.footer}>
          <p className={styles.read}>{t("Read")} →</p>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div className="w-full border-t border-gray-3"></div>
      </div>
    </Link>
  );
};

export default RelatedArticle;
