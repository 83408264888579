/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements["meta"][];
  link?: JSX.IntrinsicElements["link"][];
  title: string;
  image?: string;
  schema?: any;
}

const SEO: React.FC<Props> = props => {
  const {
    description,
    lang = "en",
    meta = [],
    link = [],
    title,
    image,
    schema
  } = props;
  const { site, sitePage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        sitePage {
          path
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | Perpetua` : null}
      script={[
        {
          type: "application/ld+json",
          innerHTML: schema && JSON.stringify(schema)
        }
      ]}
      link={([
        {
          rel: `cannonical`,
          href: site.siteMetadata?.siteUrl + sitePage.path
        }
      ] as JSX.IntrinsicElements["link"][]).concat(link)}
      meta={([
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `title`,
          property: `og:title`,
          content: defaultTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          name: `image`,
          property: `og:image`,
          content: image
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``
        },
        {
          name: `twitter:title`,
          content: defaultTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:image`,
          content: image
        }
      ] as JSX.IntrinsicElements["meta"][]).concat(meta)}
    />
  );
};

export default SEO;
