import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import cx from "classnames";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import Quote from "../Quote";
import * as styles from "./RichText.module.scss";

/*
 * Known Issue: rendering BLOCKS.EMBDDED_ENTRY breaks
 * hot reloading, because gatsby develop loses references to
 * the embedded content.
 * The work around is to delete the .cache folder in the root
 * project directory, The issue is being tracked
 * in the contentful/rich-text repo: https://github.com/contentful/rich-text/issues/94
 */
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <img
          style={{
            display: "flex",
            margin: "0 auto"
          }}
          src={node?.data?.target?.file?.url}
          alt={node?.data?.target?.file?.fileName}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const script = node?.data?.target?.script?.script;
      const quote = node?.data?.target?.quote;
      console.log(script);
      if (typeof document !== `undefined` && script) {
        var fakeDOM = document.createElement("html");
        fakeDOM.innerHTML = script;
        const scripts = fakeDOM.getElementsByTagName("script");

        for (let i = 0; i < scripts.length; i++) {
          var sc = document.createElement("script");
          sc.setAttribute("src", scripts[i].src);
          sc.setAttribute("type", scripts[i].type);
          document.head.appendChild(sc);
        }
      }
      let className = "";
      if (script?.includes("vimeo")) {
        className = cx(className, styles.vimeo);
      }

      if (quote?.quote) {
        return <Quote text={quote?.quote}/>;
      }

      return (
        <div
          className={cx(styles.embedded_content, className)}
          dangerouslySetInnerHTML={{ __html: script }}
        ></div>
      );
    },
    [INLINES]: node => {
      console.log('inline', node);
    }
  }
};

const RichText = props => {
  const { data ,extraOptions } = props;
  return (
    <div className={styles.rich_text}>
      {renderRichText(data, { ...options, ...extraOptions})}
    </div>
  );
};

export default RichText;
