import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import SEO from "../index";

interface Props {
  data?: any;
  title?: string;
  description?: string;
}
const BlogSeo: React.FC<Props> = props => {
  const { data, title, description } = props;

  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "perpetua-logo.png" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    `
  );

  const articleBody = data.body ? JSON.parse(data.body.raw) : null;

  const articleString = articleBody?.content
    .map(section => {
      return section.content[0] ? section.content[0].value : "";
    })
    .join("");

  const authors =
    data.authors?.length > 1
      ? data.authors.map(author => {
          return {
            "@type": "person",
            name: author.name
          };
        })
      : {
          "@type": "person",
          name: data.authors ? data.authors[0].name : "Perpetua Author"
        };

  const keywords = data.categories ? data.categories.map(tag => tag.name) : "";

  const pageImage =  data?.bannerImage?.fixed?.src || undefined;

  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: data?.title,
    image: pageImage,
    publisher: {
      "@type": "Organization",
      name: "Perpetua",
      url: "https://www.perpetua.io/",
      logo: logo.childImageSharp.fixed.src
    },
    url: `http://www.perpetua.io/blog/${data.slug}`,
    datePublished: data?.publishDate,
    dateCreated: data?.createdAt,
    dateModified: data?.updatedAt,
    description: data?.metaDescription || description,
    author: authors,
    articleSection: data?.categories ? data.categories[0].name : "",
    abstract: data?.bodyPreview ? data.bodyPreview : "",
    keywords: keywords,
    articleBody: articleString || data.bodyPreview,
    mainEntityOfPage: data?.title || title
  };

  return (
    <SEO
      schema={blogSchema}
      title={data?.title || title}
      description={data?.description || description}
      image={pageImage}
    />
  );
};

export default BlogSeo;
